import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import React from 'react'

export const DigitalStoreOptIns = ({ onChange, selected, storeId }) => {
  const { t } = useTranslation()

  const { options, isLoading, allowFetch } = useSelectOptionsData({
    dataType: workflowDataTypes.digitalStoreOptIns,
    parentEntityId: storeId,
    defaultOptions: selected && [selected],
    selector: optIn => ({
      value: optIn.id,
      label: optIn.title,
    }),
    fetchImmediately: true,
  })

  return (
    <Select
      id="workflow-digital-store-opt-in-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      noOptionsMessage={() => t('workflow.selectors.no_options')}
      placeholder={t('workflow.digital_store_opt_ins.placeholder')}
      isLoading={isLoading}
      onFocus={allowFetch}
    />
  )
}
