import { triggerTypes } from '../triggerTypes'
import FormSubscribedIcon from 'shared/icons/form-subscribed-icon'
import CampaignCompleteIcon from 'shared/icons/campaign-complete-icon'
import EmailLinkClickedIcon from 'shared/icons/email-link-clicked-icon'
import EmailOpenedIcon from 'shared/icons/email-opened-icon'
import CommunityIcon from 'shared/icons/community-icon'
import CourseIcon from 'shared/icons/course-icon'
import NewSaleIcon from 'shared/icons/new-sale-icon'
import PageVisitedIcon from 'shared/icons/page-visited-icon'
import WebinarIcon from 'shared/icons/webinar-icon'
import SaleCanceledIcon from 'shared/icons/sale-canceled-icon'
import AddTagIcon from 'shared/icons/add-tag-icon'
import RemoveTagIcon from 'shared/icons/remove-tag-icon'
import React from 'react'
import CourseCompletedIcon from 'shared/icons/course-completed-icon'
import ModuleCompletedIcon from 'shared/icons/module-completed-icon'
import LectureCompletedIcon from 'shared/icons/lecture-completed-icon'
import SubscriptionPaymentFailedIcon from 'shared/icons/subscription-payment-failed-icon'

export const getTriggerIcon = trigger => props => {
  switch (trigger) {
    case triggerTypes.blogFormSubscribed:
    case triggerTypes.funnelFormSubscribed:
    case triggerTypes.digitalStoreOptIn:
      return <FormSubscribedIcon {...props} />
    case triggerTypes.campaignCompleted:
      return <CampaignCompleteIcon {...props} />
    case triggerTypes.emailLinkClicked:
      return <EmailLinkClickedIcon {...props} />
    case triggerTypes.emailOpened:
      return <EmailOpenedIcon {...props} />
    case triggerTypes.enrolledInCommunity:
      return <CommunityIcon {...props} />
    case triggerTypes.enrolledInCourse:
    case triggerTypes.enrolledInCourseBundle:
      return <CourseIcon {...props} />
    case triggerTypes.courseCompleted:
      return <CourseCompletedIcon {...props} />
    case triggerTypes.moduleCompleted:
      return <ModuleCompletedIcon {...props} />
    case triggerTypes.lectureCompleted:
      return <LectureCompletedIcon {...props} />
    case triggerTypes.newSale:
      return <NewSaleIcon {...props} />
    case triggerTypes.pageVisited:
      return <PageVisitedIcon {...props} />
    case triggerTypes.registeredToWebinar:
      return <WebinarIcon {...props} />
    case triggerTypes.saleCanceled:
      return <SaleCanceledIcon {...props} />
    case triggerTypes.tagAdded:
      return <AddTagIcon {...props} />
    case triggerTypes.tagRemoved:
      return <RemoveTagIcon {...props} />
    case triggerTypes.subscriptionPaymentFailed:
      return <SubscriptionPaymentFailedIcon {...props} />
  }
}
