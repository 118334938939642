import React from 'react'
import Group from 'shared/components/Group'
import { DigitalStores } from 'workflow/components/digital-stores'
import { DigitalStoreOptIns } from 'workflow/components/digital-store-opt-ins'

export const TriggerTypeDigitalStoreFormSubscribed = ({ change, data }) => {
  const { optIn, ...store } = data

  const handleChange = property => ({ value, label }) => {
    change(
      !!property
        ? {
            ...data,
            [property]: { id: value, name: label },
          }
        : { ...data, id: value, name: label },
    )
  }

  return (
    <>
      <Group>
        <DigitalStores onChange={handleChange()} selected={store} />
      </Group>
      
      {!!store?.id && (
        <Group>
          <DigitalStoreOptIns
            storeId={store.id}
            onChange={handleChange('optIn')}
            selected={optIn}
          />
        </Group>
      )}
    </>
  )
}
