import useSelectOptionsData from 'workflow/hooks/useSelectOptionsData'
import { workflowDataTypes } from 'workflow/consts/workflowDataTypes'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import React from 'react'

export const DigitalStores = ({ onChange, selected }) => {
  const { t } = useTranslation()

  const { options, isLoading } = useSelectOptionsData({
    dataType: workflowDataTypes.digitalStores,
    defaultOptions: selected &&
      Object.values(selected).length > 0 && [selected],
    fetchImmediately: true,
  })

  return (
    <Select
      id="workflow-digital-store-select"
      onChange={onChange}
      options={options}
      maxMenuHeight={200}
      value={options.find(option => option.value === selected?.id)}
      noOptionsMessage={() => t('workflow.selectors.no_options')}
      placeholder={t('workflow.digital_stores.placeholder')}
      isLoading={isLoading}
    />
  )
}
