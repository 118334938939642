import React from 'react'
import TriggerTypePageVisited from './TriggerTypePageVisited'
import TriggerTypeWebinarRegistration from './TriggerTypeWebinarRegistration'
import TriggerTypeCourse from './TriggerTypeCourse'
import TriggerTypeCourseBundle from './TriggerTypeCourseBundle'
import TriggerTypeFunnelFormSubscribe from './TriggerTypeFunnelFormSubscribe'
import TriggerTypeBlogFormSubscribe from './TriggerTypeBlogFormSubscribe'
import TriggerTypeTag from './TriggerTypeTag'
import TriggerTypeSale from './TriggerTypeSale'
import TriggerTypeCampaignCompleted from './TriggerTypeCampaignCompleted'
import TriggerTypeEmailLinkClicked from './TriggerTypeEmailLinkClicked'
import { triggerTypes } from '../triggerTypes'
import TriggerTypeCommunity from 'workflow/components/Step/types/Trigger/TriggerType/TriggerTypeCommunity'
import { TriggerTypeDigitalStoreFormSubscribed } from './digital-store-form-subscribed'

const selectComponent = type => {
  switch (type) {
    case triggerTypes.tagRemoved:
    case triggerTypes.tagAdded:
      return [TriggerTypeTag]
    case triggerTypes.funnelFormSubscribed:
      return [TriggerTypeFunnelFormSubscribe]
    case triggerTypes.blogFormSubscribed:
      return [TriggerTypeBlogFormSubscribe]
    case triggerTypes.campaignCompleted:
      return [TriggerTypeCampaignCompleted]
    case triggerTypes.newSale:
    case triggerTypes.saleCanceled:
    case triggerTypes.subscriptionPaymentFailed:
      return [TriggerTypeSale]
    case triggerTypes.enrolledInCourseBundle:
      return [TriggerTypeCourseBundle]
    case triggerTypes.enrolledInCourse:
    case triggerTypes.courseCompleted:
    case triggerTypes.moduleCompleted:
    case triggerTypes.lectureCompleted:
      return [TriggerTypeCourse]
    case triggerTypes.enrolledInCommunity:
      return [TriggerTypeCommunity]
    case triggerTypes.registeredToWebinar:
      return [TriggerTypeWebinarRegistration]
    case triggerTypes.emailLinkClicked:
      return [TriggerTypeEmailLinkClicked]
    case triggerTypes.emailOpened:
      return [TriggerTypeEmailLinkClicked, { canChooseLink: false }]
    case triggerTypes.pageVisited:
      return [TriggerTypePageVisited]
    case triggerTypes.digitalStoreOptIn:
      return [TriggerTypeDigitalStoreFormSubscribed]
    default:
      throw new Error(`Unknown trigger type ${type}`)
  }
}

const TriggerType = ({ type, change, data }) => {
  const [Component, extraProps] = selectComponent(type)
  return <Component change={change} data={data} {...(extraProps ?? {})} />
}

export default TriggerType
